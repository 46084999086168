import React from "react";
function StrokeJoinRound({ size }) {
  return (
    <svg
      height={size}
      viewBox="0 0 7 6"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.408,3.143c0,1.563 1.268,2.83 2.831,2.83l3.116,0c0.131,0 0.237,-0.107 0.237,-0.238c0,-0.13 -0.106,-0.237 -0.237,-0.237l-3.116,0c-1.302,0 -2.357,-1.055 -2.357,-2.356l0,-2.715c0,-0.131 -0.106,-0.237 -0.237,-0.237c-0.131,0 -0.237,0.106 -0.237,0.237l0,2.715Zm2.594,-1.376c-0.657,0.113 -1.158,0.686 -1.158,1.376c0,0.77 0.625,1.395 1.395,1.395c0.69,0 1.263,-0.501 1.376,-1.159c0.006,0 0.013,0.001 0.02,0.001l1.72,0c0.131,0 0.237,-0.107 0.237,-0.237c0,-0.131 -0.106,-0.238 -0.237,-0.238l-1.72,0c-0.007,0 -0.014,0.001 -0.02,0.001c-0.1,-0.581 -0.558,-1.039 -1.139,-1.139l0,-1.503c0,-0.131 -0.106,-0.237 -0.237,-0.237c-0.131,0 -0.237,0.106 -0.237,0.237l0,1.503Zm0.237,0.454c0.509,0 0.921,0.413 0.921,0.922c0,0.508 -0.412,0.922 -0.921,0.922c-0.508,-0 -0.921,-0.413 -0.921,-0.922c0,-0.509 0.413,-0.922 0.921,-0.922Z"
        fill="#ffffff"
      ></path>
    </svg>
  );
}

export default StrokeJoinRound;
