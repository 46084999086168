import React from "react";
function StrokeJoinMiter({ size }) {
  return (
    <svg
      height={size}
      viewBox="0 0 7 6"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.45,5.738l0,0.005c0.001,0.017 0.003,0.034 0.007,0.05l0.004,0.016l0.004,0.01c0.01,0.027 0.026,0.053 0.045,0.075l0.012,0.013l0.009,0.008c0.022,0.019 0.048,0.034 0.075,0.043l0.015,0.005l0.011,0.003c0.017,0.004 0.033,0.006 0.051,0.007l5.672,0c0.131,0 0.237,-0.107 0.237,-0.237c0,-0.13 -0.106,-0.237 -0.237,-0.237l-5.432,-0l-0.041,-5.074c-0.001,-0.13 -0.108,-0.236 -0.239,-0.235c-0.131,0.001 -0.236,0.108 -0.235,0.239l0.042,5.308Zm2.552,-3.97c-0.657,0.113 -1.158,0.686 -1.158,1.376c0,0.77 0.625,1.395 1.395,1.395c0.69,-0 1.263,-0.502 1.376,-1.159c0.006,-0 0.013,0.001 0.02,0.001l1.72,-0c0.131,-0 0.237,-0.107 0.237,-0.237c0,-0.131 -0.106,-0.238 -0.237,-0.238l-1.72,-0c-0.007,-0 -0.014,0.001 -0.02,0.001c-0.1,-0.581 -0.558,-1.039 -1.139,-1.139l0,-1.503c0,-0.131 -0.106,-0.237 -0.237,-0.237c-0.131,-0 -0.237,0.106 -0.237,0.237l0,1.503Zm0.237,0.454c0.509,-0 0.921,0.413 0.921,0.922c0,0.508 -0.412,0.921 -0.921,0.921c-0.508,-0 -0.921,-0.413 -0.921,-0.921c0,-0.509 0.413,-0.922 0.921,-0.922Z"
        fill="#ffffff"
      ></path>
    </svg>
  );
}
export default StrokeJoinMiter;
