import React from "react";
function NodeCurve({ size }) {
  return (
    <svg
      height={size}
      viewBox="0 0 12 8"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.779,2.531c0.088,-1.187 1.08,-2.124 2.289,-2.124c1.217,0 2.214,0.949 2.291,2.147c2.394,0.522 3.424,1.773 3.369,4.869c-0.001,0.095 -0.08,0.171 -0.175,0.169c-0.095,-0.002 -0.171,-0.08 -0.169,-0.175c0.051,-2.865 -0.843,-3.996 -3.029,-4.511c-0.103,1.172 -1.088,2.092 -2.287,2.092c-1.208,0 -2.199,-0.935 -2.289,-2.121c-2.283,0.464 -3.213,1.618 -3.161,4.54c0.001,0.095 -0.074,0.173 -0.17,0.175c-0.095,0.002 -0.173,-0.074 -0.175,-0.169c-0.056,-3.138 1.004,-4.404 3.467,-4.889c0.013,-0.003 0.026,-0.004 0.039,-0.003Zm4.292,0.102c-0.037,-1.074 -0.92,-1.934 -2.003,-1.934c-1.105,0 -2.003,0.898 -2.003,2.004c-0,1.106 0.898,2.004 2.003,2.004c1.083,0 1.966,-0.861 2.003,-1.935c-0.014,-0.031 -0.019,-0.066 -0.011,-0.103c0.002,-0.012 0.006,-0.025 0.011,-0.036Z"
        fill="#ffffff"
      ></path>
    </svg>
  );
}
export default NodeCurve;
