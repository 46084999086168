import React, { useState, useEffect } from "react";
import useAppContext from "../../../hooks/useAppContext";
import Canvas from "../../../canvas";
import Navbar from "./Navbar";
import Panels from "./Panels";
import Cookies from "universal-cookie";

function Editor() {

  const [aboutIsOpen, setAboutIsOpen] = useState(false);
  const [showStripeModal, setShowStripeModal] = useState(true);
  const [showAccount, setShowAccount] = useState(false);
  const { isMobile } = useAppContext();

  const cookies = new Cookies();

  const openAboutModal = () => {
    setAboutIsOpen(true);
  }

  const closeAboutModal = () => {
    setAboutIsOpen(false);
  }

  const setStripeModalVisible = (flag) => {
    setShowStripeModal(flag);
  }

  const setAccountVisible = (flag) => {
    setShowAccount(flag);
  }

  return (
    <div
      style={{
        position: 'relative',
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        //BACKGROUND COLUM
        background: "#797979",
      }}
    >
      {!isMobile && <Navbar
        openAboutModal={openAboutModal}
        showStripeModal={showStripeModal}
        showAccount={showAccount}
        setAccountVisible={setAccountVisible}
        setStripeModalVisible={setStripeModalVisible} />}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <Canvas
          aboutIsOpen={aboutIsOpen}
          closeAboutModal={closeAboutModal} />
        <div style={{ position: "absolute", bottom: '10%', display: isMobile ? 'flex' : 'none' }}>
          <Panels />
        </div>
        <div style={{ display: !isMobile ? 'flex' : 'none' }}>
          <Panels />
        </div>
      </div>
    </div>
  );
}

export default Editor;
