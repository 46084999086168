import React from "react";
import { Fragment } from "react";
import PanelItem from "./PanelItem";
import PanelsList from "./PanelsList";
import useAppContext from "../../../../hooks/useAppContext";

function Panels() {
  const { isMobile, setPage, activeMenu, editor, setActiveMenu } = useAppContext();

  const setPageWithConfirm = (page) => {
    let confirm = window.confirm("Exit without saving ?");
    if (confirm) {
      setPage(page);
      setActiveMenu("Layout");
    }
  };

  const downloadImage = (type, resolution, background) => {
    resolution = resolution === "low" ? 500 : 1000;
    if (type === "png") {
      let link = document.createElement("a");
      link.href = editor.exportPng(resolution, background);
      link.download = `${"mylogo" || "logoCentrix"}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    setActiveMenu("Layout");

  };

  return (
    <Fragment>
      {activeMenu === "Menu" && <div style={{
        background: "rgb(0,208,208)",
        width: "320px",
        display: "block",
        height: "100%",
        overflowY: "auto",
      }}>
        <div style={{
          padding: "20px 10px",
          border: "1px solid rgb(0,0,0)",
          background: '#000000',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: "center",
          alignItems: "center",
        }}>
          <div style={{
            fontSize: "20pt",
            fontWeight: "bold",
            fontFamily: "system-ui",
            marginBottom: '40px'
          }}
            onClick={() => {
              setPageWithConfirm("Templates");
            }}
          >
            Restart
          </div>
          <div style={{
            fontSize: "16pt",
            fontFamily: "system-ui",
            marginBottom: '10px'
          }}>
            In the desktop version, you can Save your logo for later editing, and Download it, in different formats.
          </div>
          <a style={{
            padding: '10px 0px',
            width: '90%',
            background: '#000',
            borderRadius: 100,
            fontSize: '14px',
            fontFamily: "system-ui",
            textAlign: 'center',
            color: '#fff',
            textDecoration: 'none',
          }}
            href="https://www.youtube.com/channel/UCcF5uI4ipfSFW8MvReIjPFg" target='_blank'>
            Preview the Desktop version on YouTube
          </a>
          <div style={{
            fontSize: "20pt",
            fontFamily: "system-ui",
            marginTop: "30px",
            marginBottom: "40px"
          }}
            onClick={() => {
              downloadImage("png", "low", false, false);
            }}
          >
            Save Image
          </div>
          <div style={{
            fontSize: "20pt",
            fontFamily: "system-ui",
          }}
            onClick={() => {
              setActiveMenu("Layout");
            }}
          >
            Cancel
          </div>
        </div>
      </div>}
      {/* {isMobile && activeMenu !== "Menu" && <PanelItem />} */}
      {!isMobile && <PanelsList />}
      {!isMobile && <PanelItem />}
    </Fragment>
  );
}

export default Panels;
