export default [




  {
    fontFamily: `Aclonica-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Aclonica/Aclonica-Regular.ttf`,
  },
  {
    fontFamily: `Akshar-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/Akshar/Akshar-Bold.ttf`,
  },
  {
    fontFamily: `Akshar-Light`,
    url: `${process.env.PUBLIC_URL}/fonts/Akshar/Akshar-Light.ttf`,
  },
  {
    fontFamily: `Akshar-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Akshar/Akshar-Regular.ttf`,
  },
  {
    fontFamily: `Aleo-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/Aleo/Aleo-Bold.ttf`,
  },
  {
    fontFamily: `Aleo-BoldItalic`,
    url: `${process.env.PUBLIC_URL}/fonts/Aleo/Aleo-BoldItalic.ttf`,
  },
  {
    fontFamily: `Aleo-Italic`,
    url: `${process.env.PUBLIC_URL}/fonts/Aleo/Aleo-Italic.ttf`,
  },
  {
    fontFamily: `Aleo-Light`,
    url: `${process.env.PUBLIC_URL}/fonts/Aleo/Aleo-Light.ttf`,
  },
  {
    fontFamily: `Aleo-LightItalic`,
    url: `${process.env.PUBLIC_URL}/fonts/Aleo/Aleo-LightItalic.ttf`,
  },
  {
    fontFamily: `Aleo-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Aleo/Aleo-Regular.ttf`,
  },
  {
    fontFamily: `AnekBangla-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/Anek_Bangla/AnekBangla-Bold.ttf`,
  },
  {
    fontFamily: `AnekBangla-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Anek_Bangla/AnekBangla-Regular.ttf`,
  },
  {
    fontFamily: `AnekBangla-Thin`,
    url: `${process.env.PUBLIC_URL}/fonts/Anek_Bangla/AnekBangla-Thin.ttf`,
  },
  {
    fontFamily: `Anton-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Anton/Anton-Regular.ttf`,
  },
  {
    fontFamily: `Arvo-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/Arvo/Arvo-Bold.ttf`,
  },
  {
    fontFamily: `Arvo-BoldItalic`,
    url: `${process.env.PUBLIC_URL}/fonts/Arvo/Arvo-BoldItalic.ttf`,
  },
  {
    fontFamily: `Arvo-Italic`,
    url: `${process.env.PUBLIC_URL}/fonts/Arvo/Arvo-Italic.ttf`,
  },
  {
    fontFamily: `Arvo-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Arvo/Arvo-Regular.ttf`,
  },
  {
    fontFamily: `Audiowide-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Audiowide/Audiowide-Regular.ttf`,
  },
  {
    fontFamily: `BeauRivage-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Beau_Rivage/BeauRivage-Regular.ttf`,
  },
  {
    fontFamily: `BebasNeue-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Bebas_Neue/BebasNeue-Regular.ttf`,
  },
  {
    fontFamily: `BlackOpsOne-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Black_Ops_One/BlackOpsOne-Regular.ttf`,
  },
  {
    fontFamily: `CinzelDecorative-Black`,
    url: `${process.env.PUBLIC_URL}/fonts/Cinzel_Decorative/CinzelDecorative-Black.ttf`,
  },
  {
    fontFamily: `CinzelDecorative-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/Cinzel_Decorative/CinzelDecorative-Bold.ttf`,
  },
  {
    fontFamily: `CinzelDecorative-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Cinzel_Decorative/CinzelDecorative-Regular.ttf`,
  },
  {
    fontFamily: `Comfortaa-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/Comfortaa/Comfortaa-Bold.ttf`,
  },
  {
    fontFamily: `Comfortaa-Light`,
    url: `${process.env.PUBLIC_URL}/fonts/Comfortaa/Comfortaa-Light.ttf`,
  },
  {
    fontFamily: `DMSans-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/DM_Sans/DMSans-Bold.ttf`,
  },
  {
    fontFamily: `DMSans-Italic`,
    url: `${process.env.PUBLIC_URL}/fonts/DM_Sans/DMSans-Italic.ttf`,
  },
  {
    fontFamily: `DMSans-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/DM_Sans/DMSans-Regular.ttf`,
  },
  {
    fontFamily: `DMSerifText-Italic`,
    url: `${process.env.PUBLIC_URL}/fonts/DM_Serif_Text/DMSerifText-Italic.ttf`,
  },
  {
    fontFamily: `DMSerifText-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/DM_Serif_Text/DMSerifText-Regular.ttf`,
  },
  {
    fontFamily: `Fondamento-Italic`,
    url: `${process.env.PUBLIC_URL}/fonts/Fondamento/Fondamento-Italic.ttf`,
  },
  {
    fontFamily: `Fondamento-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Fondamento/Fondamento-Regular.ttf`,
  },
  {
    fontFamily: `FredokaOne-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Fredoka_One/FredokaOne-Regular.ttf`,
  },
  {
    fontFamily: `Glegoo-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/Glegoo/Glegoo-Bold.ttf`,
  },
  {
    fontFamily: `Glegoo-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Glegoo/Glegoo-Regular.ttf`,
  },
  {
    fontFamily: `GreatVibes-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Great_Vibes/GreatVibes-Regular.ttf`,
  },
  {
    fontFamily: `Gugi-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Gugi/Gugi-Regular.ttf`,
  },
  {
    fontFamily: `Gurajada-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Gurajada/Gurajada-Regular.ttf`,
  },
  {
    fontFamily: `Hurricane-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Hurricane/Hurricane-Regular.ttf`,
  },
  {
    fontFamily: `Kanit-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/Kanit/Kanit-Bold.ttf`,
  },
  {
    fontFamily: `Kanit-BoldItalic`,
    url: `${process.env.PUBLIC_URL}/fonts/Kanit/Kanit-BoldItalic.ttf`,
  },
  {
    fontFamily: `Kanit-Italic`,
    url: `${process.env.PUBLIC_URL}/fonts/Kanit/Kanit-Italic.ttf`,
  },
  {
    fontFamily: `Kanit-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Kanit/Kanit-Regular.ttf`,
  },
  {
    fontFamily: `Kanit-Thin`,
    url: `${process.env.PUBLIC_URL}/fonts/Kanit/Kanit-Thin.ttf`,
  },
  {
    fontFamily: `Kanit-ThinItalic`,
    url: `${process.env.PUBLIC_URL}/fonts/Kanit/Kanit-ThinItalic.ttf`,
  },
  {
    fontFamily: `KeaniaOne-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Keania_One/KeaniaOne-Regular.ttf`,
  },
  {
    fontFamily: `LibreBaskerville-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/Libre_Baskerville/LibreBaskerville-Bold.ttf`,
  },
  {
    fontFamily: `LibreBaskerville-Italic`,
    url: `${process.env.PUBLIC_URL}/fonts/Libre_Baskerville/LibreBaskerville-Italic.ttf`,
  },
  {
    fontFamily: `LibreBaskerville-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Libre_Baskerville/LibreBaskerville-Regular.ttf`,
  },
  {
    fontFamily: `Lobster-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Lobster/Lobster-Regular.ttf`,
  },
  {
    fontFamily: `Lora-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/Lora/Lora-Bold.ttf`,
  },
  {
    fontFamily: `Lora-Italic`,
    url: `${process.env.PUBLIC_URL}/fonts/Lora/Lora-Italic.ttf`,
  },
  {
    fontFamily: `Lora-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Lora/Lora-Regular.ttf`,
  },
  {
    fontFamily: `MarkoOne-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Marko_One/MarkoOne-Regular.ttf`,
  },
  {
    fontFamily: `Merriweather-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/Merriweather/Merriweather-Bold.ttf`,
  },
  {
    fontFamily: `Merriweather-Italic`,
    url: `${process.env.PUBLIC_URL}/fonts/Merriweather/Merriweather-Italic.ttf`,
  },
  {
    fontFamily: `Merriweather-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Merriweather/Merriweather-Regular.ttf`,
  },
  {
    fontFamily: `Monoton-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Monoton/Monoton-Regular.ttf`,
  },
  {
    fontFamily: `Montserrat-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/Montserrat/Montserrat-Bold.ttf`,
  },
  {
    fontFamily: `Montserrat-BoldItalic`,
    url: `${process.env.PUBLIC_URL}/fonts/Montserrat/Montserrat-BoldItalic.ttf`,
  },
  {
    fontFamily: `Montserrat-Italic`,
    url: `${process.env.PUBLIC_URL}/fonts/Montserrat/Montserrat-Italic.ttf`,
  },
  {
    fontFamily: `Montserrat-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Montserrat/Montserrat-Regular.ttf`,
  },
  {
    fontFamily: `Montserrat-Thin`,
    url: `${process.env.PUBLIC_URL}/fonts/Montserrat/Montserrat-Thin.ttf`,
  },
  {
    fontFamily: `Montserrat-ThinItalic`,
    url: `${process.env.PUBLIC_URL}/fonts/Montserrat/Montserrat-ThinItalic.ttf`,
  },
  {
    fontFamily: `MontserratAlternates-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/Montserrat_Alternates/MontserratAlternates-Bold.ttf`,
  },
  {
    fontFamily: `MontserratAlternates-BoldItalic`,
    url: `${process.env.PUBLIC_URL}/fonts/Montserrat_Alternates/MontserratAlternates-BoldItalic.ttf`,
  },
  {
    fontFamily: `MontserratAlternates-Italic`,
    url: `${process.env.PUBLIC_URL}/fonts/Montserrat_Alternates/MontserratAlternates-Italic.ttf`,
  },
  {
    fontFamily: `MontserratAlternates-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Montserrat_Alternates/MontserratAlternates-Regular.ttf`,
  },
  {
    fontFamily: `MontserratAlternates-Thin`,
    url: `${process.env.PUBLIC_URL}/fonts/Montserrat_Alternates/MontserratAlternates-Thin.ttf`,
  },
  {
    fontFamily: `MontserratAlternates-ThinItalic`,
    url: `${process.env.PUBLIC_URL}/fonts/Montserrat_Alternates/MontserratAlternates-ThinItalic.ttf`,
  },
  {
    fontFamily: `MouseMemoirs-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Mouse_Memoirs/MouseMemoirs-Regular.ttf`,
  },
  {
    fontFamily: `Notable-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Notable/Notable-Regular.ttf`,
  },
  {
    fontFamily: `OpenSans-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/Open_Sans/OpenSans-Bold.ttf`,
  },
  {
    fontFamily: `OpenSans-BoldItalic`,
    url: `${process.env.PUBLIC_URL}/fonts/Open_Sans/OpenSans-BoldItalic.ttf`,
  },
  {
    fontFamily: `OpenSans-Italic`,
    url: `${process.env.PUBLIC_URL}/fonts/Open_Sans/OpenSans-Italic.ttf`,
  },
  {
    fontFamily: `OpenSans-Light`,
    url: `${process.env.PUBLIC_URL}/fonts/Open_Sans/OpenSans-Light.ttf`,
  },
  {
    fontFamily: `OpenSans-LightItalic`,
    url: `${process.env.PUBLIC_URL}/fonts/Open_Sans/OpenSans-LightItalic.ttf`,
  },
  {
    fontFamily: `OpenSans-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Open_Sans/OpenSans-Regular.ttf`,
  },
  {
    fontFamily: `Orbitron-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/Orbitron/Orbitron-Bold.ttf`,
  },
  {
    fontFamily: `Orbitron-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Orbitron/Orbitron-Regular.ttf`,
  },
  {
    fontFamily: `Oswald-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/Oswald/Oswald-Bold.ttf`,
  },
  {
    fontFamily: `Oswald-Light`,
    url: `${process.env.PUBLIC_URL}/fonts/Oswald/Oswald-Light.ttf`,
  },
  {
    fontFamily: `Oswald-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Oswald/Oswald-Regular.ttf`,
  },
  {
    fontFamily: `Pacifico-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Pacifico/Pacifico-Regular.ttf`,
  },
  {
    fontFamily: `PermanentMarker-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Permanent_Marker/PermanentMarker-Regular.ttf`,
  },
  {
    fontFamily: `PlayfairDisplay-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/Playfair_Display/PlayfairDisplay-Bold.ttf`,
  },
  {
    fontFamily: `PlayfairDisplay-BoldItalic`,
    url: `${process.env.PUBLIC_URL}/fonts/Playfair_Display/PlayfairDisplay-BoldItalic.ttf`,
  },
  {
    fontFamily: `PlayfairDisplay-Italic`,
    url: `${process.env.PUBLIC_URL}/fonts/Playfair_Display/PlayfairDisplay-Italic.ttf`,
  },
  {
    fontFamily: `PlayfairDisplay-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Playfair_Display/PlayfairDisplay-Regular.ttf`,
  },
  {
    fontFamily: `PoiretOne-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Poiret_One/PoiretOne-Regular.ttf`,
  },
  {
    fontFamily: `Poppins-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/Poppins/Poppins-Bold.ttf`,
  },
  {
    fontFamily: `Poppins-BoldItalic`,
    url: `${process.env.PUBLIC_URL}/fonts/Poppins/Poppins-BoldItalic.ttf`,
  },
  {
    fontFamily: `Poppins-Italic`,
    url: `${process.env.PUBLIC_URL}/fonts/Poppins/Poppins-Italic.ttf`,
  },
  {
    fontFamily: `Poppins-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Poppins/Poppins-Regular.ttf`,
  },
  {
    fontFamily: `Poppins-Thin`,
    url: `${process.env.PUBLIC_URL}/fonts/Poppins/Poppins-Thin.ttf`,
  },
  {
    fontFamily: `Poppins-ThinItalic`,
    url: `${process.env.PUBLIC_URL}/fonts/Poppins/Poppins-ThinItalic.ttf`,
  },
  {
    fontFamily: `Quantico-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/Quantico/Quantico-Bold.ttf`,
  },
  {
    fontFamily: `Quantico-Italic`,
    url: `${process.env.PUBLIC_URL}/fonts/Quantico/Quantico-Italic.ttf`,
  },
  {
    fontFamily: `Quantico-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Quantico/Quantico-Regular.ttf`,
  },
  {
    fontFamily: `Quicksand-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/Quicksand/Quicksand-Bold.ttf`,
  },
  {
    fontFamily: `Quicksand-Light`,
    url: `${process.env.PUBLIC_URL}/fonts/Quicksand/Quicksand-Light.ttf`,
  },
  {
    fontFamily: `Quicksand-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Quicksand/Quicksand-Regular.ttf`,
  },
  {
    fontFamily: `Rajdhani-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/Rajdhani/Rajdhani-Bold.ttf`,
  },
  {
    fontFamily: `Rajdhani-Light`,
    url: `${process.env.PUBLIC_URL}/fonts/Rajdhani/Rajdhani-Light.ttf`,
  },
  {
    fontFamily: `Rajdhani-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Rajdhani/Rajdhani-Regular.ttf`,
  },
  {
    fontFamily: `Raleway-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/Raleway/Raleway-Bold.ttf`,
  },
  {
    fontFamily: `Raleway-BoldItalic`,
    url: `${process.env.PUBLIC_URL}/fonts/Raleway/Raleway-BoldItalic.ttf`,
  },
  {
    fontFamily: `Raleway-Italic`,
    url: `${process.env.PUBLIC_URL}/fonts/Raleway/Raleway-Italic.ttf`,
  },
  {
    fontFamily: `Raleway-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Raleway/Raleway-Regular.ttf`,
  },
  {
    fontFamily: `Raleway-Thin`,
    url: `${process.env.PUBLIC_URL}/fonts/Raleway/Raleway-Thin.ttf`,
  },
  {
    fontFamily: `Raleway-ThinItalic`,
    url: `${process.env.PUBLIC_URL}/fonts/Raleway/Raleway-ThinItalic.ttf`,
  },
  {
    fontFamily: `Righteous-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Righteous/Righteous-Regular.ttf`,
  },
  {
    fontFamily: `Roboto-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/Roboto/Roboto-Bold.ttf`,
  },
  {
    fontFamily: `Roboto-BoldItalic`,
    url: `${process.env.PUBLIC_URL}/fonts/Roboto/Roboto-BoldItalic.ttf`,
  },
  {
    fontFamily: `Roboto-Italic`,
    url: `${process.env.PUBLIC_URL}/fonts/Roboto/Roboto-Italic.ttf`,
  },
  {
    fontFamily: `Roboto-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Roboto/Roboto-Regular.ttf`,
  },
  {
    fontFamily: `Roboto-Thin`,
    url: `${process.env.PUBLIC_URL}/fonts/Roboto/Roboto-Thin.ttf`,
  },
  {
    fontFamily: `Roboto-ThinItalic`,
    url: `${process.env.PUBLIC_URL}/fonts/Roboto/Roboto-ThinItalic.ttf`,
  },
  {
    fontFamily: `RobotoSlab-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/Roboto_Slab/RobotoSlab-Bold.ttf`,
  },
  {
    fontFamily: `RobotoSlab-Light`,
    url: `${process.env.PUBLIC_URL}/fonts/Roboto_Slab/RobotoSlab-Light.ttf`,
  },
  {
    fontFamily: `RobotoSlab-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Roboto_Slab/RobotoSlab-Regular.ttf`,
  },
  {
    fontFamily: `RobotoSlab-Thin`,
    url: `${process.env.PUBLIC_URL}/fonts/Roboto_Slab/RobotoSlab-Thin.ttf`,
  },
  {
    fontFamily: `RozhaOne-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Rozha_One/RozhaOne-Regular.ttf`,
  },
  {
    fontFamily: `RussoOne-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Russo_One/RussoOne-Regular.ttf`,
  },
  {
    fontFamily: `Rye-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Rye/Rye-Regular.ttf`,
  },
  {
    fontFamily: `Sacramento-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Sacramento/Sacramento-Regular.ttf`,
  },
  {
    fontFamily: `SquarePeg-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Square_Peg/SquarePeg-Regular.ttf`,
  },
  {
    fontFamily: `Syncopate-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/Syncopate/Syncopate-Bold.ttf`,
  },
  {
    fontFamily: `Syncopate-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Syncopate/Syncopate-Regular.ttf`,
  },
  {
    fontFamily: `Tangerine-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/Tangerine/Tangerine-Bold.ttf`,
  },
  {
    fontFamily: `Tangerine-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Tangerine/Tangerine-Regular.ttf`,
  },
  {
    fontFamily: `TitanOne-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Titan_One/TitanOne-Regular.ttf`,
  },
  {
    fontFamily: `Ubuntu-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/Ubuntu/Ubuntu-Bold.ttf`,
  },
  {
    fontFamily: `Ubuntu-BoldItalic`,
    url: `${process.env.PUBLIC_URL}/fonts/Ubuntu/Ubuntu-BoldItalic.ttf`,
  },
  {
    fontFamily: `Ubuntu-Italic`,
    url: `${process.env.PUBLIC_URL}/fonts/Ubuntu/Ubuntu-Italic.ttf`,
  },
  {
    fontFamily: `Ubuntu-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Ubuntu/Ubuntu-Regular.ttf`,
  },
  {
    fontFamily: `Ultra-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Ultra/Ultra-Regular.ttf`,
  },
  {
    fontFamily: `Updock-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Updock/Updock-Regular.ttf`,
  },
  {
    fontFamily: `VollkornSC-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/Vollkorn_SC/VollkornSC-Bold.ttf`,
  },
  {
    fontFamily: `VollkornSC-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Vollkorn_SC/VollkornSC-Regular.ttf`,
  },
  {
    fontFamily: `Whisper-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Whisper/Whisper-Regular.ttf`,
  },
  {
    fontFamily: `YanoneKaffeesatz-Bold`,
    url: `${process.env.PUBLIC_URL}/fonts/Yanone_Kaffeesatz/YanoneKaffeesatz-Bold.ttf`,
  },
  {
    fontFamily: `YanoneKaffeesatz-Light`,
    url: `${process.env.PUBLIC_URL}/fonts/Yanone_Kaffeesatz/YanoneKaffeesatz-Light.ttf`,
  },
  {
    fontFamily: `YanoneKaffeesatz-Regular`,
    url: `${process.env.PUBLIC_URL}/fonts/Yanone_Kaffeesatz/YanoneKaffeesatz-Regular.ttf`,
  },

];
