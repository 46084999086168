import React from "react";
function StrokeCapSquare({ size }) {
  return (
    <svg
      height={size}
      viewBox="0 0 7 6"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.362,0.169l-5.717,-0c-0.131,-0 -0.237,0.106 -0.237,0.237c0,0.131 0.106,0.237 0.237,0.237l5.473,-0l0,4.714l-5.473,0c-0.131,0 -0.237,0.106 -0.237,0.237c0,0.131 0.106,0.237 0.237,0.237l5.71,0c0.114,0 0.209,-0.08 0.232,-0.188l0.002,-0.014l0.002,-0.012l0.001,-0.014l0,-5.197c0,-0.11 -0.076,-0.203 -0.178,-0.23l-0.012,-0.002l-0.026,-0.004l-0.014,-0.001Zm-3.971,2.594l-1.746,-0c-0.131,-0 -0.237,0.106 -0.237,0.237c0,0.131 0.106,0.237 0.237,0.237l1.746,-0c0.113,0.658 0.686,1.159 1.376,1.159c0.77,-0 1.395,-0.626 1.395,-1.396c-0,-0.77 -0.625,-1.395 -1.395,-1.395c-0.69,-0 -1.263,0.501 -1.376,1.158Zm1.376,-0.684c0.508,-0 0.921,0.413 0.921,0.921c-0,0.509 -0.413,0.921 -0.921,0.921c-0.509,-0 -0.921,-0.412 -0.921,-0.921c-0,-0.508 0.412,-0.921 0.921,-0.921Z"
        fill="#ffffff"
      ></path>
    </svg>
  );
}

export default StrokeCapSquare;
