import Symmetry from "./Symmetry";
import Layout from "./Layout";
import Text from "./Text";
import Mockup from "./Mockup";
class PanelItems {}
PanelItems.Symmetry = Symmetry;
PanelItems.Layout = Layout;
PanelItems.Text = Text;
PanelItems.Mockup = Mockup;
export default PanelItems;
