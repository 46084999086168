import * as React from "react";

const MockupEmpty = ({ size, onClick }) => (
  <img
    style={{
      width: `${size}%`,
      cursor: "pointer"
    }}
    onClick={onClick}
    src={"/images/mockup_empty.png"} alt="" />
);

export default MockupEmpty;