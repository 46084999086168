import React from "react";
function StrokeJoinBevel({ size }) {
  return (
    <svg
      height={size}
      viewBox="0 0 7 6"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.43,3.644c0.014,0.031 0.035,0.058 0.062,0.081l2.594,2.193c0.022,0.018 0.046,0.032 0.072,0.041c0.025,0.009 0.052,0.014 0.081,0.014l3.116,0c0.131,0 0.237,-0.107 0.237,-0.238c-0,-0.13 -0.106,-0.237 -0.237,-0.237l-3.029,0l-2.444,-2.065l-0,-3.006c-0,-0.13 -0.106,-0.236 -0.237,-0.236c-0.131,-0 -0.237,0.106 -0.237,0.237l-0,3.116c-0,0.036 0.008,0.069 0.022,0.1Zm2.572,-1.877c-0.657,0.113 -1.158,0.686 -1.158,1.376c-0,0.769 0.625,1.394 1.395,1.394c0.69,0 1.263,-0.501 1.376,-1.159c0.006,0 0.013,0.001 0.02,0.001l1.72,0c0.131,0 0.237,-0.107 0.237,-0.237c-0,-0.131 -0.106,-0.238 -0.237,-0.238l-1.72,0c-0.007,0 -0.014,0.001 -0.02,0.001c-0.1,-0.581 -0.558,-1.039 -1.139,-1.139l-0,-1.503c-0,-0.131 -0.106,-0.237 -0.237,-0.237c-0.131,0 -0.237,0.106 -0.237,0.237l-0,1.503Zm0.237,0.454c0.509,0 0.921,0.413 0.921,0.922c-0,0.508 -0.412,0.921 -0.921,0.921c-0.508,0 -0.921,-0.413 -0.921,-0.921c-0,-0.509 0.413,-0.922 0.921,-0.922Z"
        fill="#ffffff"
      ></path>
    </svg>
  );
}

export default StrokeJoinBevel;
