import StrokeCapRound from "./StrokeCapRound";
import StrokeCapSquare from "./StrokeCapSquare";
import StrokeCapButt from "./StrokeCapButt";
import StrokeJoinRound from "./StrokeJoinRound";
import StrokeJoinBevel from "./StrokeJoinBevel";
import StrokeJoinMiter from "./StrokeJoinMiter";
import NodeAdd from "./NodeAdd";
import NodeRemove from "./NodeRemove";
import NodeStraight from "./NodeStraight";
import NodeCurve from "./NodeCurve";
import Text from "./Text";
import Layout from "./Layout";
import Symmetry from "./Symmetry";
import Mockup from "./Mockup";
import LogoCentrix from "./LogoCentrix";
import LogoTop from "./LogoTop";
import LogoLeft from "./LogoLeft";
import LogoBottom from "./LogoBottom";
import LogoRight from "./LogoRight";
import CircleShape from "./CircleShape";
import LineShape from "./LineShape";
import RectangleShape from "./RectangleShape";
import TriangleShape from "./TriangleShape";
import RhombusShape from "./RhombusShape";
import PentagonShape from "./PentagonShape";
import HexagonShape from "./HexagonShape";
import AlignLeft from "./AlignLeft";
import AlignCenter from "./AlignCenter";
import AlignRight from "./AlignRight";
import ZoomPlus from "./ZoomPlus";
import ZoomMinus from "./ZoomMinus";
import SelectTemplate from "./SelectTemplate";
import Template3Sides from "./Template3Sides";
import Template4Sides from "./Template4Sides";
import Template6Sides from "./Template6Sides";
import Template8Sides from "./Template8Sides";
import MockupEmpty from "./MockupEmpty";
import MockupPic1 from "./MockupPic1";
import MockupPic3 from "./MockupPic3";
import MockupPic5 from "./MockupPic5";
import MockupPic6 from "./MockupPic6";
import MockupPic7 from "./MockupPic7";
import MockupPic8 from "./MockupPic8";
import MockupPic9 from "./MockupPic9";
import MockupPic10 from "./MockupPic10";
import MockupPic11 from "./MockupPic11";
import MockupPic12 from "./MockupPic12";




class Icons {}
Icons.StrokeCapRound = StrokeCapRound;
Icons.StrokeCapSquare = StrokeCapSquare;
Icons.StrokeCapButt = StrokeCapButt;
Icons.StrokeJoinRound = StrokeJoinRound;
Icons.StrokeJoinBevel = StrokeJoinBevel;
Icons.StrokeJoinMiter = StrokeJoinMiter;
Icons.NodeAdd = NodeAdd;
Icons.NodeRemove = NodeRemove;
Icons.NodeStraight = NodeStraight;
Icons.NodeCurve = NodeCurve;
Icons.Text = Text;
Icons.Layout = Layout;
Icons.Symmetry = Symmetry;
Icons.Mockup = Mockup;
Icons.LogoCentrix = LogoCentrix;
Icons.LogoTop = LogoTop;
Icons.LogoLeft = LogoLeft;
Icons.LogoBottom = LogoBottom;
Icons.LogoRight = LogoRight;
Icons.CircleShape = CircleShape;
Icons.LineShape = LineShape;
Icons.RectangleShape = RectangleShape;
Icons.TriangleShape = TriangleShape;
Icons.RhombusShape = RhombusShape;
Icons.PentagonShape = PentagonShape;
Icons.HexagonShape = HexagonShape;
Icons.AlignLeft = AlignLeft;
Icons.AlignCenter = AlignCenter;
Icons.AlignRight = AlignRight;
Icons.ZoomPlus = ZoomPlus;
Icons.ZoomMinus = ZoomMinus;
Icons.SelectTemplate = SelectTemplate;
Icons.Template3Sides = Template3Sides;
Icons.Template4Sides = Template4Sides;
Icons.Template6Sides = Template6Sides;
Icons.Template8Sides = Template8Sides;
Icons.MockupEmpty = MockupEmpty;
Icons.MockupPic1 = MockupPic1;
Icons.MockupPic3 = MockupPic3;
Icons.MockupPic5 = MockupPic5;
Icons.MockupPic6 = MockupPic6;
Icons.MockupPic7 = MockupPic7;
Icons.MockupPic8 = MockupPic8;
Icons.MockupPic9 = MockupPic9;
Icons.MockupPic10 = MockupPic10;
Icons.MockupPic11 = MockupPic11;
Icons.MockupPic12 = MockupPic12;







export default Icons;
