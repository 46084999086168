import React from "react";
import useAppContext from "../../../../hooks/useAppContext";
import { styled } from "baseui";
import PanelItems from "./PanelItems";

function PanelsList() {
  const { activePanel, isMobile, activeMenu } = useAppContext();

  const Container = styled("div", (props) => ({


//BACKGROUND PANEL
    background: "#424242",


    width: "320px",
    display: "block",
    height: "100%",
    borderLeft: "1px solid rgb(169,169,169) !important",
    border: isMobile && activePanel === activeMenu ? "1px solid #000 !important" : "0px solid rgb(0,0,0)",
    overflowY: "auto",
  }));
  const Component = PanelItems[activePanel];

  return <Container>{Component && <Component />}</Container>;
}

export default PanelsList;
