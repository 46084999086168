import BaseHandler from "./BaseHandler";
class MockupHandler extends BaseHandler {
  constructor(props) {
    super(props);
  }

  setBackgroundImage(path) {
    this.editor.discardActiveSelection();
    let elements = this.editor.handlers.canvas.project.activeLayer.children;
    let rasterMatch = elements
      .map((item, index) => elements[elements.length - 1 - index])
      .find((children) => children._class === "Raster");
    if (rasterMatch) {
      let position = rasterMatch.getPosition();
      rasterMatch.remove();

      let raster = new this.editor.handlers.canvas.Raster(path);
      raster.position = position;
      raster.scale(1);
    } else {
      let raster = new this.editor.handlers.canvas.Raster(path);
      raster.position = this.editor.handlers.canvas.view.center;
      raster.scale(1);
    }
    this.editor.fitToScreen();
  }

  setBackgroundImageZoom(zoom) {
    this.editor.discardActiveSelection();
    let elements = this.editor.handlers.canvas.project.activeLayer.children;
    let rasterMatch = elements
      .map((item, index) => elements[elements.length - 1 - index])
      .find((children) => children._class === "Raster");

    if (rasterMatch) {
      let path = rasterMatch.getSource();
      let position = rasterMatch.getPosition();
      rasterMatch.remove();

      let raster = new this.editor.handlers.canvas.Raster(path);
      raster.position = position;
      raster.scale(zoom/100);
    }

  }
}
export default MockupHandler;
