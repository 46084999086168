import React from "react";
function NodeStraight({ size }) {
  return (
    <svg
      height={size}
      viewBox="0 0 12 9"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.041,3.07c0,-0.045 0.017,-0.089 0.05,-0.123l2.787,-2.786c0.067,-0.067 0.176,-0.067 0.244,-0l2.786,2.787c0.034,0.034 0.051,0.078 0.051,0.122c0,0.044 -0.017,0.088 -0.051,0.122l-1.298,1.299c0.039,0.003 0.077,0.019 0.107,0.049l4.056,4.055c0.068,0.069 0.068,0.178 0,0.245c-0.067,0.067 -0.176,0.067 -0.243,-0l-4.056,-4.056c-0.03,-0.03 -0.047,-0.068 -0.05,-0.108l-1.302,1.302c-0.068,0.068 -0.177,0.068 -0.244,-0l-1.302,-1.302c-0.003,0.04 -0.02,0.078 -0.05,0.108l-4.056,4.056c-0.067,0.067 -0.176,0.067 -0.244,-0c-0.067,-0.067 -0.067,-0.176 0,-0.244l4.056,-4.056c0.03,-0.03 0.069,-0.046 0.108,-0.049l-1.299,-1.299c-0.033,-0.034 -0.05,-0.078 -0.05,-0.122Zm2.959,2.543l2.543,-2.543l-2.543,-2.543l-2.543,2.543l2.543,2.543Z"
        fill="#ffffff"
      />
    </svg>
  );
}

export default NodeStraight;
