import React, { useState } from "react";
import useAppContext from "../../../../hooks/useAppContext";
import { styled } from "baseui";
import PanelListItem from "./PanelListItem";

const panelListItems = [
  {
    id: "menu",
    name: "Menu",
  },

  {
    id: "layout",
    name: "Layout",
  },

  {
    id: "symmetry",
    name: "Symmetry",
  },

  {
    id: "text",
    name: "Text",
  },

  {
    id: "mockup",
    name: "Mockup",
  },
];

function PanelsList() {
  const { activePanel, isMobile } = useAppContext();

  const Container = styled("div", () => ({
    width: isMobile ? "304px" : "76px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    flexDirection: isMobile ? "row" : "column",
  }));

  return (
    <Container>
      <div style={{
        width: isMobile ? "304px" : "76px",
        display: "flex",
        flexDirection: isMobile ? "row" : "column",
        height: '100%',
        //border left
        borderLeft: isMobile ? "0px" : "1px solid rgb(169,169,169)"
      }}>
        {panelListItems.map((panelListItem) => (
          <PanelListItem
            label={panelListItem.name}
            name={panelListItem.name}
            key={panelListItem.name}
            icon={panelListItem.name}
            activePanel={activePanel}
          />
        ))}
      </div>
    </Container>
  );
}

export default PanelsList;
