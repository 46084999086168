import React from "react";
function StrokeCapSquare({ size }) {
  return (
    <svg
      height={size}
      viewBox="0 0 7 6"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.399,0.169l-4.944,0c-0.131,0 -0.237,0.106 -0.237,0.237c-0,0.131 0.106,0.237 0.237,0.237l4.695,0l0,0.982c-0.581,0.099 -1.039,0.558 -1.138,1.138l-3.557,0c-0.131,0 -0.237,0.106 -0.237,0.237c-0,0.131 0.106,0.237 0.237,0.237l3.557,0c0.099,0.581 0.557,1.039 1.138,1.138l0,0.982l-4.695,0c-0.131,0 -0.237,0.106 -0.237,0.237c-0,0.131 0.106,0.237 0.237,0.237l4.932,0c0.109,0 0.201,-0.073 0.228,-0.174l0.003,-0.011l0.003,-0.014l0.002,-0.014l0.001,-0.013l0,-1.23c0.657,-0.112 1.158,-0.686 1.158,-1.375c0,-0.689 -0.501,-1.263 -1.158,-1.375l0,-1.219c0,-0.118 -0.087,-0.217 -0.201,-0.234l-0.012,-0.002l-0.012,-0.001Zm-0.249,1.941l-0,1.78c-0.394,-0.104 -0.684,-0.463 -0.684,-0.89c-0,-0.426 0.29,-0.786 0.684,-0.89Zm0.474,0c0.394,0.104 0.684,0.464 0.684,0.89c-0,0.427 -0.29,0.786 -0.684,0.89l-0,-1.78Z"
        fill="#ffffff"
      ></path>
    </svg>
  );
}

export default StrokeCapSquare;
