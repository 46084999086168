import React from "react";
function MockupPic5({ size, onClick }) {
  return (
    <img
      style={{
        width: `${size}%`,
        border: "1px solid black",
        cursor: "pointer"
       }}
      onClick={onClick}
      src={"/images/mockup_t-shirt 1black.jpg"} alt="" />
  );
}
export default MockupPic5;
