import React from "react";
function Text({ size }) {
  return (
    <svg
      height={size}
      viewBox="0 0 6 6"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.681,0.014l0,1.616l-0.158,0c-0.094,-0.372 -0.198,-0.64 -0.313,-0.803c-0.114,-0.163 -0.271,-0.293 -0.471,-0.39c-0.111,-0.053 -0.307,-0.079 -0.585,-0.079l-0.445,0l0,4.606c0,0.306 0.017,0.496 0.05,0.573c0.034,0.075 0.1,0.142 0.199,0.199c0.098,0.058 0.232,0.087 0.402,0.087l0.199,-0l0,0.163l-3.127,-0l0,-0.163l0.199,-0c0.173,-0 0.312,-0.031 0.418,-0.093c0.076,-0.042 0.136,-0.111 0.18,-0.212c0.033,-0.07 0.049,-0.254 0.049,-0.555l0,-4.605l-0.432,0.001c-0.402,-0 -0.694,0.085 -0.876,0.255c-0.255,0.238 -0.417,0.577 -0.484,1.016l-0.167,0l0,-1.616l5.362,0Z"
        fill="#ffffff"
      ></path>
    </svg>
  );
}
export default Text;
