import BaseHandler from "./BaseHandler";
class SymmetryHandler extends BaseHandler {
  constructor(props) {
    super(props);
    this.symmetries = [];
  }
  addSymmetry(item, amount, center, mirror, flipState) {
    let items = [item];
    if (!items[0]) return;
    items[0].amount = amount;
    items[0].flipState = flipState;
    items[0].excludeFromExport = true;
    items[0].symmetryIndex = this.symmetries.length;
    items[0].symmetryCenter = center || this.canvas.view.center;
    // items[0].mirror = mirror || false;
    items[0].mirror = mirror || false;
    for (let i = 1; i < amount; i++) {
      items.push(item.clone());
      items[i].amount = amount;
      items[i].flipState = flipState;
      items[i].symmetryIndex = this.symmetries.length;
      items[i].excludeFromExport = true;
      items[i].symmetryCenter = center || this.canvas.view.center;
      items[i].mirror = mirror[i]
    }
    this.symmetries.push(items);
    this.updateSymmetry(item);
  }
  updateSymmetry(item) {
    if (!this.symmetries[item.symmetryIndex]) return;
    let items = this.symmetries[item.symmetryIndex];
    let center = item.symmetryCenter;
    let vector = item.bounds.center.subtract(center);
    if (items.indexOf(item) !== 0) {
      items.unshift(items.splice(items.indexOf(item), 1)[0]);
    }

    if (!item.flipState) {
      for (let i = 0; i < items.length / 2; i++) {
        if (items[i] !== item) {
          // handle single
          items[i].copyAttributes(item);
          items[i].copyContent(item);
          items[i].position = center.add(vector);
          items[i].rotate((360 / items.length * 2) * i);
        }
        vector.angle += 360 / items.length * 2;
      }
      for (let i = items.length / 2; i < items.length; i++) {
        items[i].copyAttributes(item);
        items[i].copyContent(item);
        items[i].position = center.add(vector);
        items[i].rotate((360 / items.length * 2) * i);
        vector.angle += 360 / items.length * 2;
        items[i].rotate(180);
        items[i].scale(1,-1);
      }
      for (let i = items.length / 2; i < items.length; i++) {
        items[i].position.x = 2 * center.x - items[i].position.x;
      }
    } else {
      for (let i = 0; i < items.length; i++) {
        if (items[i] !== item) {
          // handle single
          items[i].copyAttributes(item);
          items[i].copyContent(item);
          items[i].position = vector.add(center);
          items[i].rotate((360 / items.length) * i);
        }
        vector.angle += 360 / items.length;
      }
    }


  }
  getDistanceFromCenter(item) {
    return item.position.subtract(item.symmetryCenter);
  }
  setSymmetryCenter(item, center) {
    let distance = this.getDistanceFromCenter(item);
    let items = this.symmetries[item.symmetryIndex];
    for (let i = 0; i < items.length; i++) {
      items[i].symmetryCenter = center;
    }
    item.position = center.add(distance);
    this.updateSymmetry(item);
  }
  setFlipOn(item, flag) {
    if (!this.symmetries[item.symmetryIndex]) return;
    let items = this.symmetries[item.symmetryIndex];
    if (items.indexOf(item) !== 0) {
      items.unshift(items.splice(items.indexOf(item), 1)[0]);
    }
    for (let i = 1; i < items.length; i++) {
      if (items[i].mirrorItem) {
        items[i].mirrorItem.remove();
        delete items[i].mirrorItem;
      }
      items[i].remove();
    }
    items.splice(1);
    item.amount = !flag ? item.amount * 2 : item.amount / 2;
    item.flipState = flag;

    let center = item.symmetryCenter;
    let vector = item.bounds.center.subtract(center);

    if (!flag) {
      for (let i = 1; i < item.amount / 2; i++) {
        let clone = item.clone();
        clone.parent = this.canvas.project.activeLayer;
        items.push(clone);
        items[i].amount = item.amount;
        items[i].flipState = item.flipState;
        items[i].symmetryIndex = item.symmetryIndex;
        items[i].excludeFromExport = true;
        items[i].symmetryCenter = item.symmetryCenter;
        items[i].mirror = item.mirror;
      }
  
      for (let i = item.amount / 2; i < item.amount; i++) {
        let clone = item.clone();
        clone.getCurves().map(curve => {
          let pointX1 = 2 * center.x - curve.getPoint1().x;
          let pointX2 = 2 * center.x - curve.getPoint2().x;
          curve.setPoint1(pointX1, curve.getPoint1().y);
          curve.setPoint2(pointX2, curve.getPoint2().y);
        })
        clone.parent = this.canvas.project.activeLayer;
        items.push(clone);
        items[i].amount = item.amount;
        items[i].flipState = item.flipState;
        items[i].symmetryIndex = item.symmetryIndex;
        items[i].excludeFromExport = true;
        items[i].symmetryCenter = item.symmetryCenter;
        items[i].mirror = item.mirror;
        // items[i].mirror = true;
      }
  
      for (let i = 0; i < items.length / 2; i++) {
        if (items[i] !== item) {
          // handle single
          items[i].copyAttributes(item);
          items[i].copyContent(item);
          items[i].position = center.add(vector);
          items[i].rotate((360 / items.length * 2) * i);
        }
        vector.angle += 360 / items.length * 2;
      }
      for (let i = items.length / 2; i < items.length; i++) {
        items[i].copyAttributes(item);
        items[i].copyContent(item);
        items[i].position = center.add(vector);
        items[i].rotate((360 / items.length * 2) * i);
        vector.angle += 360 / items.length * 2;
        items[i].rotate(180);
        items[i].scale(1,-1);
      }
      for (let i = items.length / 2; i < items.length; i++) {
        items[i].position.x = 2 * center.x - items[i].position.x;
      }
    } else {
      for (let i = 1; i < item.amount; i++) {
        let clone = item.clone();
        clone.parent = this.canvas.project.activeLayer;
        items.push(clone);
        items[i].amount = item.amount;
        items[i].flipState = item.flipState;
        items[i].symmetryIndex = item.symmetryIndex;
        items[i].excludeFromExport = true;
        items[i].symmetryCenter = item.symmetryCenter;
        items[i].mirror = item.mirror;
      }

      for (let i = 0; i < items.length; i++) {
          items[i].mirror = false;
        if (items[i] !== item) {
          items[i].copyAttributes(item);
          items[i].copyContent(item);
          items[i].position = center.add(vector);
          item.amount % 2 == 0?
          items[i].rotate((360 / items.length) * i):
          items[i].rotate((360 / items.length * 2) * i);
          }
        item.amount % 2 == 0?
        vector.angle += 360 / items.length:
        vector.angle += 360 / items.length * 2;
      }
    }
  }
  setSymmetrySides(item, value) {
    let items = this.symmetries[item.symmetryIndex];
    if (items.indexOf(item) !== 0) {
      items.unshift(items.splice(items.indexOf(item), 1)[0]);
    }
    for (let i = 1; i < items.length; i++) {
      if (items[i].mirrorItem) {
        items[i].mirrorItem.remove();
        delete items[i].mirrorItem;
      }
      items[i].remove();
    }
    items.splice(1);
    item.amount = value;
    item.flipState = true;
    for (let i = 1; i < item.amount; i++) {
      let clone = item.clone();
      clone.parent = this.canvas.project.activeLayer;
      items.push(clone);
      items[i].amount = item.amount;
      items[i].flipState = item.flipState;
      items[i].symmetryIndex = item.symmetryIndex;
      items[i].excludeFromExport = true;
      items[i].symmetryCenter = item.symmetryCenter;
      items[i].mirror = item.mirror;
    }
    this.updateSymmetry(item);
  }
  setSymmetryMirror(item, value) {
    let items = this.symmetries[item.symmetryIndex];
    for (let i = 0; i < items.length; i++) {
      items[i].mirror = value;
    }
    this.updateSymmetry(item);
  }
  exportSymmetry() {
    let output = [];
    this.symmetries.forEach((symmetry) => {
      let mirror = [];
      for (let i = 0; i < symmetry.length; i++){
        mirror.push(symmetry[i].mirror);
      }
      output.push({
        amount: symmetry.length,
        element: symmetry[0] ? symmetry[0].exportJSON() : null,
        mirror: mirror,
        flipState: symmetry[0] ? symmetry[0].flipState : null,
        center: symmetry[0]
          ? { x: symmetry[0].symmetryCenter.x, y: symmetry[0].symmetryCenter.y }
          : { x: this.canvas.view.center.x, y: this.canvas.view.center.x.y },
      });
    });
    return output;
  }
}
export default SymmetryHandler;
